var environment = {
  eventSources: {
    // Page loaded events
    PAGE_REGISTRATION_STEP1_LOADED: 'web.page.registration.step1.loaded',
    PAGE_REGISTRATION_STEP2_LOADED: 'web.page.registration.step2.loaded',
    PAGE_REGISTRATION_STEP3_LOADED: 'web.page.registration.step3.loaded',

    PAGE_NEW_REGISTRATION_STEP1_LOADED:
      'web.page.new.registration.step1.loaded',
    PAGE_NEW_REGISTRATION_STEP2_LOADED:
      'web.page.new.registration.step2.loaded',
    PAGE_NEW_REGISTRATION_STEP3_LOADED:
      'web.page.new.registration.step3.loaded',
    PAGE_NEW_REGISTRATION_STEP4_LOADED:
      'web.page.new.registration.step4.loaded',
    PAGE_NEW_REGISTRATION_STEP5_LOADED:
      'web.page.new.registration.step5.loaded',

    PAGE_LOGIN_LOADED: 'web.page.login.loaded',
    PAGE_REGISTERED_LOADED: 'web.page.registered.loaded',
    PAGE_INDEX_LOADED: 'web.page.index.loaded',

    // Next page clicked
    EVENT_REGISTRATION_STEP1_NEXT_PAGE_CLICKED:
      'web.event.registration.step1.next.clicked',
    EVENT_REGISTRATION_STEP2_NEXT_PAGE_CLICKED:
      'web.event.registration.step2.next.clicked',
    EVENT_REGISTRATION_STEP3_NEXT_PAGE_CLICKED:
      'web.event.registration.step3.next.clicked',
    EVENT_REGISTRATION_STEP4_NEXT_PAGE_CLICKED:
      'web.event.registration.step4.next.clicked',
    EVENT_REGISTRATION_STEP5_NEXT_PAGE_CLICKED:
      'web.event.registration.step5.next.clicked',

    // Action Events
    EVENT_REGISTRATION_SIGN_AGREEMENT: 'web.event.registration.sign_agreement',

    EVENT_REGISTRATION_START: 'web.event.registration.start',
    EVENT_LOGIN_START: 'web.event.login.start',
    EVENT_SEND_CALLBACK_REQUEST: 'web.event.send.callback_request',

    //Juicy events
    EVENT_JUICY_LOAD_REQUEST: 'web.event.juicy.script.load.request',
    EVENT_JUICY_LOADED: 'web.event.juicy.script.loaded',
    EVENT_JUICY_ERROR: 'web.event.juicy.script.error',
    EVENT_JUICY_EXTRACT_COOKIE: 'web.event.juicy.script.extract.cookie',
    EVENT_JUICY_EXTRACT_COOKIE_NEW: 'web.event.juicy.script.extract.cookie.new',

    //Common error event, when exception crush a page
    EVENT_COMMON_ERROR: 'web.event.common.error',
  },
};
const load = function (url) {
  // This promise will be used by Promise.all to determine success or failure
  return new Promise(function (resolve, reject) {
    const element = document.createElement('script');
    const parent = 'head';
    const attr = 'src';
    element.async = false;
    // // Important success and error for the promise
    element.onload = function () {
      resolve(url);
    };
    element.onerror = function () {
      reject(url);
    };
    element[attr] = url;
    document[parent].appendChild(element);
  });
};
let envBrand;

function stepCallback(step, brand) {
  envBrand = brand;
  Promise.all([
    load('/funnel/js/jquery-1.10.2.min.js'),
    load('/funnel/js/fingerprint2.min.js'),
    load('/funnel/js/eventbus.core.js'),
  ])
    .then(function () {
      console.log('Callback for registration step: ' + step + envBrand);
      if (window.funnelCore) {
        let source = null;
        switch (step) {
          case 0:
            source =
              environment.eventSources.PAGE_NEW_REGISTRATION_STEP1_LOADED;
            break;
          case 2:
            source =
              environment.eventSources.PAGE_NEW_REGISTRATION_STEP2_LOADED;
            break;
          case 3:
            source =
              environment.eventSources.PAGE_NEW_REGISTRATION_STEP3_LOADED;
            break;
          case 4:
            source =
              environment.eventSources.PAGE_NEW_REGISTRATION_STEP4_LOADED;
            break;
          case 5:
            source =
              environment.eventSources.PAGE_NEW_REGISTRATION_STEP5_LOADED;
            break;
          default:
            source = environment.eventSources.PAGE_REGISTRATION_STEP1_LOADED;
            console.warn('Default Callback for registration step');
        }
        funnelCore.assignCallback(function () {
          funnelCore.pageVisitedEvent(source, envBrand);
        });
      }
    })
    .catch(function () {
      console.log('script download failure');
    });
}

function actionCallback(step, name) {
  // On succesfull confirmation: step == 1, name == confirmation
  // Not actually a button press event for confirmation, so there is no element with that name

  if (window.funnelCore && step == 1 && name == 'confirmation') {
    funnelCore.webEvent(
      environment.eventSources.EVENT_REGISTRATION_SIGN_AGREEMENT,
      envBrand
    );
  }
  console.log('Callback for button press');
}

function actionClickNextPage(step, params) {
  console.log(envBrand);
  if (window.funnelCore) {
    try {
      const tags = params == null ? new Array('') : new Array(params);
      switch (step) {
        case 1:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP1_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
          break;
        case 2:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP2_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
          break;
        case 3:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP3_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
          break;
        case 4:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP4_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
          break;
        case 5:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP5_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
          break;
        default:
          funnelCore.webEvent(
            environment.eventSources.EVENT_REGISTRATION_STEP1_NEXT_PAGE_CLICKED,
            envBrand,
            tags
          );
      }
    } catch (e) {
      console.log('Error in funnel core' + '\n' + e.stack);
    }
  }
}

function handleJuicyEvent(type, juicyCookie) {
  console.log(`Handle juicy event ${type}`);
  try {
    if (window.funnelCore) {
      const tags =
        juicyCookie == null
          ? new Array('juicy cookie not set')
          : new Array(juicyCookie);
      switch (type) {
        //Before loading script
        case 0:
          funnelCore.webEvent(
            environment.eventSources.EVENT_JUICY_LOAD_REQUEST,
            envBrand,
            tags
          );
          break;
        //After loading script
        case 1:
          funnelCore.webEvent(
            environment.eventSources.EVENT_JUICY_LOADED,
            envBrand,
            tags
          );
          break;
        //Error occurred when loading script
        case 2:
          funnelCore.webEvent(
            environment.eventSources.EVENT_JUICY_ERROR,
            envBrand,
            tags
          );
          break;
        //When extract cookie from storage
        case 3:
          funnelCore.webEvent(
            environment.eventSources.EVENT_JUICY_EXTRACT_COOKIE,
            envBrand,
            tags
          );
          break;
        case 4:
          funnelCore.webEvent(
            environment.eventSources.EVENT_JUICY_EXTRACT_COOKIE_NEW,
            envBrand,
            tags
          );
          break;
        default:
          console.error(`unknown event type ${type}`);
      }
    }
  } catch (e) {
    console.log(`Error in send event \n ${e.stack}`);
  }
}

function sendErrorEvent(stackTrace) {
  try {
    if (window.funnelCore) {
      funnelCore.webEvent(
        environment.eventSources.EVENT_COMMON_ERROR,
        envBrand
      );
    }
  } catch (e) {
    console.log(`Error when send event \n ${e.stack}`);
  }
}
